// utils/homeUtils.js
import AWS from "aws-sdk";
import gsap from "gsap";

// Configure AWS SDK
AWS.config.update({
  region: "ap-south-1",
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "ap-south-1:b7d72c97-9da1-40a3-b54d-9ffecdc1a2f6", // Cognito Identity Pool ID
  }),
});

const s3 = new AWS.S3();

// Fetch images from S3 and sort by LastModified date
export const fetchImages = async () => {
  const params = {
    Bucket: "hypstonic",
    Prefix: "storage1/PNG/",
  };

  try {
    const data = await s3.listObjectsV2(params).promise();

    // Sort images by LastModified date in descending order
    const sortedContents = data.Contents.sort(
      (a, b) => new Date(b.LastModified) - new Date(a.LastModified)
    );
    const url = sortedContents.map((val) => {
      return val.Key;
    });
    const path = url.map((val) => {
      return val.split("/")[2];
    });
    return path;
  } catch (error) {
    console.error("Error fetching images:", error);
    return [];
  }
};

// Apply staggered animation to cards
export const staggeredCards = (cardContainer, classname, delay = 0) => {
  const container = cardContainer?.current;
  if (!container) return;

  const cards = Array.from(container.querySelectorAll(classname));

  // Reset the opacity of all cards
  gsap.set(cards, { opacity: 0, autoAlpha: 0 });

  // Apply staggered animation
  gsap.to(cards, {
    opacity: 1,
    autoAlpha: 1,
    stagger: 0.045,
    ease: "power2.inOut",
    delay: delay,
  });
};

// Apply mode-based animations using GSAP
export const applyModeAnimations = (mode) => {
  if (mode === "interactive") {
    gsap.fromTo(
      "#pixelstream",
      0.25,
      { opacity: 0, ease: "power2.inOut" },
      { opacity: 1, ease: "power2.inOut" }
    );
  } else {
    gsap.fromTo(
      "#pixelstream",
      0.25,
      { opacity: 1, ease: "power2.inOut" },
      { opacity: 0, ease: "power2.inOut" }
    );
  }

  if (mode === "capture") {
    gsap.fromTo(
      "#unrealcaptures",
      0.25,
      { opacity: 0, ease: "power2.inOut" },
      { opacity: 1, ease: "power2.inOut" }
    );
  } else {
    gsap.fromTo(
      "#unrealcaptures",
      0.25,
      { opacity: 1, ease: "power2.inOut" },
      { opacity: 0, ease: "power2.inOut" }
    );
  }

  if (mode === "interactive") {
    gsap.fromTo(
      "#unreadSidebar",
      0.25,
      {
        minWidth: 0,
        maxWidth: 0,
        padding: "0.391vw 0 2.031vw",
        margin: "0.625vw 0",
        ease: "power2.inOut",
        overflow: "hidden",
      },
      {
        minWidth: "15.625vw",
        padding: "0.391vw 0.391vw 2.031vw",
        margin: "0.625vw 0 0.625vw 0.625vw",
        ease: "power2.inOut",
      }
    );
  } else {
    gsap.fromTo(
      "#unreadSidebar",
      0.25,
      {
        minWidth: "15.625vw",
        ease: "power2.inOut",
        padding: "0.391vw 0.391vw 2.031vw",
        margin: "0.625vw 0 0.625vw 0.625vw",
      },
      {
        minWidth: 0,
        maxWidth: 0,
        padding: "0.391vw 0 2.031vw",
        margin: "0.625vw 0",
        overflow: "hidden",
        ease: "power2.inOut",
      }
    );
  }
};

// Handle lens, bodycolor, and lighting change
export const handleLens = (number) => {
  const obj = { lens: number };
  document.getElementById("iframe_1").contentWindow.postMessage(obj, "*");
};

export const handleBodycolor = (number) => {
  const obj = { bodycolor: number };
  document.getElementById("iframe_1").contentWindow.postMessage(obj, "*");
};

export const handleLighting = (number) => {
  const obj = { lighting: number };
  document.getElementById("iframe_1").contentWindow.postMessage(obj, "*");
};

// Handle messages between Unreal and frontend
export const handleMessage = (event, setMessage) => {
  if (event.data.value === "loadingComplete") {
    setMessage("loadingComplete");
  } else if (
    typeof event.data === "object" &&
    event.data.hasOwnProperty("debug")
  ) {
    setMessage("debug");
  }
};

// Debug handler for Unreal
export const handleDebug = () => {
  const obj = { debugMe: 1 };
  document.getElementById("iframe_1").contentWindow.postMessage(obj, "*");
};

// Terminate Unreal session
export const handleTerminateSession = () => {
  const terminateObj = { terminateSession: 1 };
  document
    .getElementById("iframe_1")
    .contentWindow.postMessage(terminateObj, "*");
};




// if we want to download fbx below is the code
export const generateDownloadURL = async (bucketName, fileKey) => {
  const params = {
    Bucket: bucketName,
    Key: fileKey, // Specify the file's exact key
    Expires: 3600, // URL validity in seconds (1 hour)
  };

  try {
    const url = await s3.getSignedUrlPromise("getObject", params);
    console.log("Generated URL:", url);
    return url;
  } catch (error) {
    console.error("Error generating download URL:", error);
    return null;
  }
};

// Usage example
const bucketName = "hypstonic"; // Replace with your bucket name
const fileKey = "storage1/FBX/240913_152112.fbx"; // Replace with your file's key

generateDownloadURL(bucketName, fileKey).then((url) => {
  if (url) {
    console.log("Download this file using URL:", url);
  }
});